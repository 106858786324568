export function scaleUniform (original,scale){
    const rate = {
        width : original.width / scale.width,
        height : original.height / scale.height,
    }

    if(rate.width > rate.height) {
        return {
            width : scale.width,
            height : original.height / rate.width,
        }
    } else {
        return {
            width : original.width / rate.height,
            height : scale.height,
        } 
    }
}