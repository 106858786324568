import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export function CardImage({ title }) {
    const [titleLines, setTitleLines] = useState([]);

    useEffect(() => {
        setTitleLines(title.split(' '));
    }, [title]);

    return (
        <div className='card-image'>
            <img src='./images/card_image_base.png' alt='base' />
            <div className='card-image-title'>
                {titleLines.map((t, i) => <svg key={i} xmlns="http://www.w3.org/2000/svg">
                    <text x="50%" y="50%" dominantBaseline="central" textAnchor="middle">{t}</text>
                </svg>)}
            </div>

        </div>
    );
}

CardImage.propTypes = {
    title: PropTypes.string
};

CardImage.defaultProps = {
    title: ""
};


export default connect(null, null)(CardImage);