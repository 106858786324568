import React from 'react';
import { MarqueeSegment } from '../molecules/MarqueeSegment';
import { TitleSegment } from '../molecules/TitleSegment';
import HomeVideoSelection from '../molecules/HomeVideoSelection';

export function Home() {

    return (
        <main className="home">
            <section>
                <TitleSegment />
            </section>

            <HomeVideoSelection />

            <section className="horizontal">

                <figure className="textPanel">
                    <p>
                        Expert debugging is a site dedicated to provide guidance on debugging applications on windows.
                    </p>
                    <p>
                        The guidance provided is aimed to help newbies as well as seasoned professionals in sharpening their
                        debugging skills.
                    </p>
                    <p>
                        This series will cover the basics, memory dump analysis, live debugging and various other common debugging scenarios.
                    </p>

                </figure>
                <div>
                    <MarqueeSegment />
                </div>
            </section>
        </main>
    );
}