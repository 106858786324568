import React from 'react';
export function PrivacyPolicy() {
  return (
    <main className="tutorial">
      <article>
        <h1>Privacy Policy</h1>
        <p>Your privacy is important to us. It is ExpertDebuggings policy to respect your privacy regarding any information we may collect from you across our website, and other sites we own and operate.</p>
        <p>We will only ask for persoman information to provide a service. The collection of that information will abide by all laws pertaining to that information.</p>
        <p>You will need ask to provide consent when personal information is obtained from you.</p>
        <p>We will let you know how the information is going to be used.</p>
        <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</p>
        <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>
        <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
        <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
        <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
        <p>This policy is effective as of 17 April 2020.</p>
      </article>
    </main>
  );
}