import React from 'react';
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { TutorialMarkDown } from '../organisms/TutorialMarkdown';
//import { navigate } from '../util/util';
import { useWindowSize } from '../hooks/windowSizeHook';
import { scaleUniform } from '../util/scaleRect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updatedSelectedPage } from '../features/tutorialsSlice';

function mapStateToProps(state) {
  const tutorials = state.tutorials.value;
  if (tutorials.selected && tutorials.selectedPage) {
    const selected = tutorials[tutorials.selected];
    const index = selected.content.findIndex(c => c.page.markdown === tutorials.selectedPage);
    if (index >= 0) {
      const content = selected.content[index];
      let next = null;
      let previous = null;
      if (index < selected.content.length - 1) {
        next = selected.content[index + 1].page.markdown;
      }

      if (index >= 1) {
        previous = selected.content[index - 1].page.markdown;
      }

      return {
        content: {
          next: next,
          previous: previous,
          ...content
        }
      }
    }
  }
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatedSelectedPage: (selected) => dispatch(updatedSelectedPage(selected))
  }
}


export function TutorialPage({ content, updatedSelectedPage }) {
  const navigate = useNavigate();
  const onNext = () => {
    updatedSelectedPage(content.next);
    navigate('/tutorialpage');
  }

  const onPrevious = () => {
    updatedSelectedPage(content.previous);
    navigate('/tutorialpage');
  }

  const createButton = (clickHandler, icon, text) => {
    return (<button onClick={clickHandler}>
      <FontAwesomeIcon icon={icon} size="5x" />
      <p>{text}</p>
    </button>);
  };

  const isValidSize = (size) => {
    return size.width && size.height;
  }

  const maxVideoSize = { width: 640, height: 360 };
  const windowSize = useWindowSize();
  const videoSize = scaleUniform(maxVideoSize, {
    width: Math.min(maxVideoSize.width, windowSize.width),
    height: Math.min(maxVideoSize.height, windowSize.height),
  });

  if (isValidSize(videoSize)) {

    const youtubeConfig = {
      url: content.page.youtubeLink,
      ...videoSize
    }

    return (
      <main className="tutorial">
        <header>
          <nav className='previous'>
            {content.previous &&
              createButton(onPrevious, faArrowCircleLeft, "Previous Article")
            }
          </nav>
          <h1>{content.page.title}</h1>
          <nav className='next'>
            {content.next &&
              createButton(onNext, faArrowCircleRight, "Next Article")
            }
          </nav>
        </header>
        <section>
          <article className='video'>
            {youtubeConfig.url &&
              <ReactPlayer className='youtube-player' {...youtubeConfig} />
            }
          </article>
          <TutorialMarkDown markdown={content.page.markdown} folder={content.page.folder} />
        </section>
      </main >
    );
  } else {
    return <div />
  }
}

  TutorialPage.propTypes = {
    content: PropTypes.shape({
      route: PropTypes.string,
    }),
    updatedSelectedPage: PropTypes.func

  };

  TutorialPage.defaultProps = {
    content: null,
    updatedSelectedPage: () => { }
  };

  export default connect(mapStateToProps, mapDispatchToProps)(TutorialPage);