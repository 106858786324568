import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';

export function TutorialMarkDown(props) {
    const folder = props.folder ?? "tutorial";
    const [codeText, setCodeText] = useState();
    useEffect(() => {
        const url = `/${folder}/${props.markdown}.md`;
         fetch(url)
             .then((response) => {
                 return response.text();
             })
             .then((data) => {
                setCodeText(data);
             });
    }, [props.markdown, folder]);

    return (<article>
        {!codeText &&
            <section className="loading">
                <img src="/images/gear.png" alt="loading icon"/>
                <p>Loading tutorial</p>
            </section>
            
        }
        {codeText &&
            <Markdown className='markdown'>{codeText}</Markdown>
        }
    </article>
    );
}