import { createSlice } from '@reduxjs/toolkit';

export const tutorialsSlice = createSlice({
  name: 'tutorials',
  initialState: {
    value: {
      windbg : null,
      nodeJs : null,
      reviews : null,
      virus : null,
      selected : "windbg",
      selectedPage : "howwindbgworks"
    },
  },
  reducers: {
    updateWinDBGTutorials: (state, action) => {
      state.value.windbg = action.payload;
    },

    updateNodeJSTutorials: (state, action) => {
      state.value.nodeJs = action.payload;
    },

    updateReviews: (state, action) => {
      state.value.reviews = action.payload;
    },

    updateVirus: (state, action) => {
      state.value.virus = action.payload;
    },

    updateSelected: (state,action) => {
      state.value.selected = action.payload;
    },

    updatedSelectedPage: (state,action) => {
      state.value.selectedPage = action.payload;
    }
  },
})

export const { updateWinDBGTutorials,updateNodeJSTutorials,updateReviews,updateVirus,updateSelected,updatedSelectedPage} = tutorialsSlice.actions
export default tutorialsSlice.reducer