import { configureStore } from '@reduxjs/toolkit';
import tutorialsReducer from '../features/tutorialsSlice';


const reducer = {
  tutorials : tutorialsReducer,
};

export default configureStore({
  reducer: reducer,
});

export function configureStoreWithInitialState(state) {
  return configureStore({
    reducer : reducer,
    preloadedState : state
  })
}
