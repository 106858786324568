import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { updatedSelectedPage } from '../features/tutorialsSlice';
import {CardImage} from './CardImage';


const mapDispatchToProps = (dispatch) => {
    return {
        updatedSelectedPage: (selected) => dispatch(updatedSelectedPage(selected))
    }
}

export function Card({ markdown, name, title, description }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateTo = () => {
        dispatch(updatedSelectedPage(markdown));
        navigate('/tutorialpage');
    }

    return (
        <button className={classnames(name, 'card')} onClick={() => navigateTo()}>
            <div className='innerFrame'>
                <div>
                    <CardImage title={title}/>
                </div>
                <h3>{title}</h3>
                {description &&
                    <FontAwesomeIcon icon={faExclamationCircle} size="1x" />
                }
                <h4>{description}</h4>
            </div>
        </button>
    );
}

Card.propTypes = {
    markdown: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
};

Card.defaultProps = {
    markdown: "",
    name: "",
    title: "",
    description: ""
};


export default connect(null, mapDispatchToProps)(Card);