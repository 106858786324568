import React from 'react';

export function HomeFooter() {
    return (
        <footer className='home'>
            <nav>
                <a href="TermsAndConditions">Terms and Conditions</a>
                <a href="PrivacyPolicy">Privacy Policy</a>
            </nav>
        </footer>
    )
}