import React from 'react';
import { ImageRoll } from './imageRoll';

export function MarqueeSegment() {
    
    const marqueImages = ["./images/imageRoll0.jpg","./images/imageRoll1.jpg","./images/imageRoll2.jpg","./images/imageRoll3.jpg"];

    return (
        <section className='marquee'>
            <ImageRoll images={marqueImages} />
        </section>
    );
}