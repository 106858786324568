import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateSelected } from '../features/tutorialsSlice';

const mapDispatchToProps = (dispatch) => {
    return {
        updateSelected: (selected) => dispatch(updateSelected(selected))
    }
}


export function NavigationPanel({ updateSelected }) {
    const navigate = useNavigate();
    const [overMenuClass, setOverMenuClass] = useState("");

    const navigateTo = (selected) => {
        updateSelected(selected);
        navigate("/tutorialvideos")
    }

    return (
        <nav className='navigationPanel' onMouseLeave={() => setOverMenuClass("")}>
            <button className='home' onClick={() => {navigate("/")}}><img src="./images/homelogo.svg" alt="home logo" /><p>Expert Debugging</p></button>
            <div>
                <div className={classnames(overMenuClass, "watch-menu")} onMouseEnter={() => setOverMenuClass("over")}>Watch</div>
                <nav className={overMenuClass}>
                    <button onClick={() => navigateTo("windbg")} className="menu-item">WinBDG Videos</button>
                    <button onClick={() => navigateTo("nodeJs")} className="menu-item">NodeJs Videos</button>
                    <button onClick={() => navigateTo("reviews")} className="menu-item">Review Videos</button>
                    <button onClick={() => navigateTo("virus")} className="menu-item">Virus Videos</button>
                </nav>
            </div>
        </nav>
    );
}



NavigationPanel.propTypes = {
    updateSelected: PropTypes.func

};

NavigationPanel.defaultProps = {
    updateSelected: () => { }
};

export default connect(null, mapDispatchToProps)(NavigationPanel);