import React from 'react';

export function TitleSegment() {


    return (
        <div className="titleSegment">
            <h1>Expert Debugging</h1>
            <h2>Tutorials on WinDBG & more</h2>
            <img src="./images/homelogo.svg" alt="home logo"/>

        </div>
    );
}