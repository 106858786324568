export default function getTutorials(url) {
    return fetch(url)
        .then((response) => {
            return response.json();
        }).then((response) => {
            return response.tutorials;
        });
}

export function getReviews() {
    return getTutorials('/reviews/reviews.json');
}

export function getWindbgTutorials() {
    return getTutorials('/windbg/tutorials.json');
}

export function getNodejsTutorials() {
    return getTutorials('/nodejs/nodejsTutorials.json');
}

export function getVirus() {
    return getTutorials('/virus/virus.json');
}