import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import { config } from './config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { DatabaseContext } from './context/dbcontext';
import { Provider } from 'react-redux';
import store from './app/store';

// Initialize Firebase
firebase.initializeApp(config);
firebase.analytics();

const db = firebase.firestore();
if (process.env.NODE_ENV === 'development') {
  db.useEmulator("localhost", 8080);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
  <DatabaseContext.Provider value={db}>
    <Provider store={store}>
      <App />
    </Provider>
  </DatabaseContext.Provider>
</React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
