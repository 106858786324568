import React, { useState } from 'react';
import { Card } from '../molecules/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useWindowSize } from '../hooks/windowSizeHook';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


function mapStateToProps(state) {
    const {windbg} = state.tutorials.value;
    return {
        windbg
    };
}


export function HomeVideoSelection({windbg}) {
    const [startIndex, setStartIndex] = useState(0);
    const windowSize = useWindowSize();

    if(windbg) {
        const allTutorials = windbg.content.map(t => <Card href={t.route} {...t.page} {...t.card} />);


        const cardWidth = 400;
        const MaxViewList = Math.round(windowSize.width / cardWidth);
        return (
            <section className='videoSelection'>
                <p>
                    Videos are provided by High voice computing, available on Youtube at <a href='https://www.youtube.com/channel/UCzyVY_3Woh7BWcUiBPRx09Q'>High Voice Computing</a>
                </p>

                <div className="carousell">
                    <nav>
                        <div className="before">
                            <FontAwesomeIcon onClick={() => setStartIndex(Math.max(startIndex - 1, 0))} icon=
                                {faChevronCircleLeft} size="3x" />
                        </div>
                    </nav>

                    {allTutorials.slice(startIndex, startIndex + MaxViewList).map((card,index) => <div key={index}>{card}</div>)}
                    <nav>
                        <div className="after">
                            <FontAwesomeIcon onClick={() => setStartIndex(Math.min(startIndex + 1, allTutorials.length - MaxViewList))} icon={faChevronCircleRight} size="3x" />
                        </div>
                    </nav>

                </div>
            </section>
        );
    } else {
        return (<div></div>);
    }
}

HomeVideoSelection.propTypes = {
    windbg: PropTypes.shape({
        content : PropTypes.array
    }),
};

HomeVideoSelection.defaultProps = {
    windbg : undefined
};


export default connect(mapStateToProps, null)(HomeVideoSelection);