import React from 'react';
import classnames from 'classnames';
import { Card } from '../molecules/card';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function mapStateToProps(state) {
    const tutorials = state.tutorials.value;
    if (tutorials.selected) {
        const selected = tutorials[tutorials.selected];
        return {
            selected
        }
    }
    return {};
}


export function TutorialVideos({ selected }) {
    if (selected) {
        const allReviews = selected.content.map(t => <Card href={t.route} {...t.page} {...t.card} />);

        return (
            <main className="home">
                <section className="horizontal">
                    <aside className={classnames('panel', selected.theme)}>
                        <p>{selected.header}</p>
                    </aside>
                    {allReviews &&
                        <div className='videolist'>
                            {allReviews.map((card, index) => <div key={index}>{card}</div>)}
                        </div>
                    }
                </section>
            </main>
        );
    } else {
        return (<div />);
    }
}

TutorialVideos.propTypes = {
    selected: PropTypes.shape({})

};

TutorialVideos.defaultProps = {
    selected: null
};


export default connect(mapStateToProps, null)(TutorialVideos);