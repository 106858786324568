import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Home } from '../templates/Home';
import { PrivacyPolicy } from '../templates/PrivacyPolicy';
import { TermsAndConditions } from '../templates/TermsAndConditions';
import NavigationPanel from '../organisms/NavigationPanel';
import TutorialVideos from '../templates/TutorialVideos';
import { getWindbgTutorials, getReviews, getNodejsTutorials, getVirus } from '../context/getTutorialsFactory';
import { HomeFooter } from '../molecules/HomeFooter';
import TutorialPage from '../templates/TutorialPage';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {updateWinDBGTutorials,updateNodeJSTutorials,updateReviews,updateVirus} from '../features/tutorialsSlice';
import 'fomantic-ui/dist/semantic.min.css';
import '../sass/pages/App.scss';


const mapDispatchToProps = (dispatch) => {
  return {
    updateWinDBGTutorials: (tutorials) => dispatch(updateWinDBGTutorials(tutorials)),
    updateNodeJSTutorials: (tutorials) => dispatch(updateNodeJSTutorials(tutorials)),
    updateReviews: (tutorials) => dispatch(updateReviews(tutorials)),
    updateVirus: (tutorials) => dispatch(updateVirus(tutorials)),
  }
}

function App({updateWinDBGTutorials,updateNodeJSTutorials,updateReviews,updateVirus}) {

  useEffect(() => {
    getNodejsTutorials().then(tutorials => {
      updateNodeJSTutorials(tutorials);
    });
    getWindbgTutorials().then(tutorials => {
      updateWinDBGTutorials(tutorials);
    });
    getReviews().then(tutorials => {
      updateReviews(tutorials);
    });
    getVirus().then(tutorials => {
      updateVirus(tutorials);
    });
  }, [updateWinDBGTutorials,updateNodeJSTutorials,updateReviews,updateVirus]);

  return (
    <div className="App">
      <Suspense fallback={null}>
          <BrowserRouter>
            <nav className="router">
              <NavigationPanel />
              <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}/>
                <Route path="/TermsAndConditions" element={<TermsAndConditions />}/>
                <Route path="/tutorialvideos" element={<TutorialVideos/>}/>
                <Route path="/tutorialpage" element={<TutorialPage/>}/>
              </Routes>
            </nav>
            <HomeFooter />
          </BrowserRouter>
      </Suspense>
    </div>
  );
}

App.propTypes = {
  updateWinDBGTutorials: PropTypes.func,
  updateNodeJSTutorials: PropTypes.func,
  updateReviews: PropTypes.func,
  updateVirus: PropTypes.func

};

App.defaultProps = {
  content: null,
  updateWinDBGTutorials : () => {},
  updateNodeJSTutorials : () => {},
  updateReviews : () => {},
  updateVirus : () => {}
};

export default connect(null, mapDispatchToProps)(App);