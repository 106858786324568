import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export function ImageRoll({fadeTimeout,images,autoHide}) {

    const [bottomImage, setBottomImage] = useState(1);
    const [topImage, setTopImage] = useState(0);

    const [fadeState, setFadeState] = useState(0);

    const onTransitionState = () => {
        if (fadeState < 3) {
            setFadeState(fadeState + 1);
        } else {
            setFadeState(0);
        }

        if (fadeState === 1) {
            setTopImage(bottomImage);
        }

        if (fadeState === 3) {
            const getNextImageIndex = (index) => {
                const nextIndex = index + 1;
                return nextIndex < images.length ? nextIndex : 0;
            };
            const nextBottomImage = getNextImageIndex(bottomImage);
            setBottomImage(nextBottomImage);
        }
    }

    useEffect(() => {

        const timer = setTimeout(() => {
            onTransitionState();
        }, fadeTimeout);
        return () => clearTimeout(timer);
    });

    const shouldFade = () => {
        return fadeState === 1 || fadeState === 2; 
    }

    const topImageClass = () => {
        return shouldFade() ? "fadeout" : "";
    }


    const bottomImageClassName = classnames("imageRollImage",);
    const topImageClassName = classnames("imageRollImage", topImageClass());

    return (
        <div className={classnames("imageRoll",{"image-roll-automatic-hide":autoHide})}>
            <img className={bottomImageClassName} src={images[bottomImage]} alt="bottom" />
            <img className={topImageClassName} src={images[topImage]} alt="top" />
        </div>
    );
}

ImageRoll.propTypes = {
    fadeTimeout: PropTypes.number,
    images: PropTypes.array.isRequired,
    autoHide : PropTypes.bool
};

ImageRoll.defaultProps = {
    fadeTimeout: 5000,
    autoHide : true,
};
